import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HeroSlider from '../components/HeroSlider';
import SliderAndText from '../components/SliderAndText';
import FullWidthParallax from '../components/FullWidthParallax';
import IndexPageComponents from '../components/IndexPageComponents';
import MetricsCounter from '../components/MetricsCounter';
import Layout from '../components/Layout';
import PageHelmet from '../components/PageHelmet';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const IndexPageTemplate = ({
  heroImages,
  heroTitle,
  sliderImages,
  sliderObject,
  videoBackgroundImage,
  seoObject,
  videoId,
  pageComponents,
  counterItems,
  helmet,
}) => (
  <div className='container content vertically-centered'>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <HeroSlider heroImages={heroImages} heroTitle={heroTitle} />
    <SliderAndText sliderObject={sliderObject} sliderImages={sliderImages} />
    <FullWidthParallax videoBackgroundImage={videoBackgroundImage} />
    <div className='index-page-container'>
      <IndexPageComponents pageComponents={pageComponents} />
    </div>
    <MetricsCounter counterItems={counterItems} />
  </div>
);

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <IndexPageTemplate
        location={location}
        heroImages={frontmatter.heroImages}
        sliderImages={frontmatter.sliderImages}
        sliderObject={frontmatter.sliderObject}
        heroTitle={frontmatter.heroTitle}
        counterItems={frontmatter.counterItems}
        seoObject={frontmatter.seoObject}
        videoBackgroundImage={frontmatter.videoBackgroundImage}
        videoId={frontmatter.videoId}
        pageComponents={frontmatter.pageComponents}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImages
              .slice(0, 1)
              .map((item) => item.image)}`}
          />
        }
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heroImages {
          image
          animation
          position
          overlay
          heroTitle
          heroSubtitle
          buttonObject {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
        }
        sliderImages {
          image
        }
        sliderObject {
          title
          text
          button {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
          backgroundTitle
          backgroundTriangle
        }
        counterItems {
          count
          title
        }
        seoObject {
          metaTitle
          metaDescription
        }
        videoBackgroundImage
        videoId
        pageComponents {
          alignment
          images {
            alignment
            isTitle
            textObject {
              text
              textColor
            }
            linkObject {
              link
              isExternal
            }
            imageObject {
              image
              overlay
            }
          }
        }
      }
    }
  }
`;
